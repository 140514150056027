import React from 'react';
import './index.module.css';
import styles from './index.module.css'
import Grid from '@mui/material/Unstable_Grid2';
import Button from '@mui/material/Button';
import productImages from "../../assets/productImages.zip"


function Download () {
  return (
    <div id='download' className={styles['download-container']}>
        <div className={styles['title-container']}>
            <h1>Download</h1>
        </div>
        <div className={styles['text-container']}>
            <p>
                Click on the buttons below to download some useful stuff. You can download the official GN Tobacco brochure that contains all the product info you need. You can also download a package of high quality product images.
            </p>
        </div>
        <div className={styles['download-btn']}>
            <Grid xs={12} md={12} className={styles['btn-view-all-container']}>
                <a href='https://drive.google.com/file/d/1qhRPrbtuEKEw2jZ2FKUckpysVQYH_iy7/view?usp=sharing' download target='_blank'  without rel="noreferrer">
                    <Button
                        className={styles['btn']}
                        variant="contained">
                        GN Brochure
                    </Button>
                </a>
                <a href={productImages} download target='_blank'  without rel="noreferrer">
                    <Button
                        className={styles['btn']}
                        variant="contained">
                        Product Images
                    </Button>
                </a>
            </Grid>
        </div>
    </div>
  );
}

export default Download;
