import React, { useState, useEffect } from 'react';
import styles from './index.module.css'
import Grid from '@mui/material/Unstable_Grid2';

const ProductCard = React.lazy(() => import('../products'));


function ProductsList ({productList}) {

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
        }, 1);
    }, []);

    return (
        <div>
            {loading ? (
                <div>
                </div>
            ) : (
                    <div>
                        <Grid spacing={3} className={styles['product-container']} container >
                            {productList.map((product, index) => {
                                return (
                                    <Grid xs={6} md={3} className={styles['products']} key={index}>
                                        <ProductCard
                                            className={styles['products']}
                                            product={product}
                                            index={index}
                                        />
                                    </Grid>
                                )
                            })}
                    </Grid>
                    </div>                
                )}
        </div>
    );
}

export default ProductsList;
