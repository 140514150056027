export const bulldogProducts = [
  {
    name: 'BULL DOG CANVAS COLD EXTREME',
    header: 'ALL WHITE PORTION',
    description: 'Bull Dog is a near "All White" product with just a minimum of tobacco. Powerful but well rounded and flavorful flavor blend with clear and cooling flavors of real mint oils.',
    form: 'Portion',
    manufacturer: 'GN Tobacco',
    productType: 'White Portion',
    netWeight: '-',
    salt: '-',
    nicotineLevel: '22 mg/g',
    moisture: '-',
    image: `${process.env.PUBLIC_URL}/img/products/bulldog/bulldogColdExtreme.png`
  },
  {
    name: 'BULL DOG CANVAS EXTREME',
    form: 'Portion',
    header: 'ALL WHITE PORTION',
    description: 'Bull Dog is a near "All White" product with just a minimum of tobacco. Powerful but well rounded and flavorful blend with classic and spicy Swedish tobacco flavor - peppery and with hints of citrus/bergamot.',
    manufacturer: 'GN Tobacco',
    productType: 'White Portion',
    netWeight: '-',
    salt: '-',
    nicotineLevel: '22 mg/g',
    moisture: '-',
    image: `${process.env.PUBLIC_URL}/img/products/bulldog/bulldogCanvasExtreme.png`
  }
]

export const whiteFoxProducts = [
  {
    name: 'WHITE FOX TIGHT',
    header: 'ALL WHITE PORTION',
    description: 'White Fox is a white, tobacco-free product with a fresh spearmint flavoring. By being sufficiently loaded with nicotine and essential natural oils, White Fox is one of the purest ways to attain nicotine satisfaction.',
    form: 'Portion',
    manufacturer: 'GN Tobacco',
    productType: 'All White Portion',
    netWeight: '15 g',
    salt: '-',
    nicotineLevel: '16mg/g',
    moisture: '-',
    image: `${process.env.PUBLIC_URL}/img/products/whitefox/whiteFoxSlim-min.png`
  },
  {
    name: 'WHITE FOX PEPPERED MINT TIGHT',
    form: 'Portion',
    description: 'White Fox Peppered Mint is a white, tobacco-free product with a very fresh peppermint flavoring, topped of by a hint of black pepper oil - spicy! By being sufficiently loaded with nicotine and essential natural oils, White Fox is one of the purest ways to attain nicotine satisfaction.',
    manufacturer: 'GN Tobacco',
    productType: 'All White Portion',
    netWeight: '15 g',
    salt: '-',
    nicotineLevel: '16 mg/g',
    moisture: '-',
    image: `${process.env.PUBLIC_URL}/img/products/whitefox/whiteFoxPepperedMint-min.png`
  },
  {
    name: 'WHITE FOX DOUBLE MINT TIGHT',
    header: 'ALL WHITE PORTION',
    description: 'White Fox Double Mint is a white, tobacco-free product with a fresh mint-menthol flavoring. By being sufficiently loaded with nicotine and essential natural oils, White Fox is one of the purest ways to attain nicotine satisfaction.',
    form: 'Portion',
    manufacturer: 'GN Tobacco',
    productType: 'All White Portion',
    netWeight: '15 g',
    salt: '-',
    nicotineLevel: '16 mg/g',
    moisture: '-',
    image: `${process.env.PUBLIC_URL}/img/products/whitefox/whiteFoxDoubleMint-min.png`
  },
  {
    name: 'WHITE FOX BLACK TIGHT',
    form: 'Portion',
    description: 'White Fox Black Tight is a white, tobacco-free product with a dark and smoky tobacco flavor and fresh aftertaste. With a high nicotine content of 30mg/g and essential natural oils, White Fox Black is one of the purest ways to attain nicotine satisfaction.',
    manufacturer: 'GN Tobacco',
    productType: 'All White Portion',
    netWeight: '15 g',
    salt: '-',
    nicotineLevel: '30 mg/g',
    moisture: '-',
    image: `${process.env.PUBLIC_URL}/img/products/whitefox/whiteFoxBlackEdition-min.png`
  },
  {
    name: 'WHITE FOX FULL CHARGE',
    header: 'ALL WHITE PORTION',
    description: 'White Fox Full Charge is a white, tobacco free product with a Strong and unique mint/spearmint combination delivering a comforting burn and cooling sensation.',
    form: 'Portion',
    manufacturer: 'GN Tobacco',
    productType: 'All White Portion',
    netWeight: '15 g',
    salt: '-',
    nicotineLevel: '16.5 mg/g',
    moisture: '-',
    image: `${process.env.PUBLIC_URL}/img/products/whitefox/whiteFoxFullCharge-min.png`
  }
]

export const siberiaAllWhiteProducts = [
  {
    name: 'SIBERIA SUPER TIGHT',
    description: 'New Siberia All-White Portion The famous flavor or Siberia is now available, as a Tobacco-free All-White portion in a dynamic range of portion sizes.',
    header: 'ALL WHITE PORTION',
    form: 'Portion',
    manufacturer: 'GN Tobacco',
    productType: 'All White Portion',
    netWeight: '15 g',
    salt: '-',
    nicotineLevel: '33 mg/g',
    moisture: '46-47 %',
    image: `${process.env.PUBLIC_URL}/img/products/siberia/siberia-super-slim-min.png`
  },
  {
    name: 'SIBERIA ULTRA TIGHT',
    description: 'New Siberia All-White Portion - The famous flavor of Siberia is now available, as a Tobacco-free All-White portion in a dynamic range of portion sizes.',
    form: 'Portion',
    manufacturer: 'GN Tobacco',
    productType: 'All White Portion',
    netWeight: '15 g',
    salt: '-',
    nicotineLevel: '33 mg/g',
    moisture: '46-47 %',
    image: `${process.env.PUBLIC_URL}/img/products/siberia/siberia-ultra-slim-min.png`
  },
  {
    name: 'SIBERIA TIGHT',
    description: 'New Siberia All-White Portion - The famous flavor of Siberia is now available, as a Tobacco-free All-White portion in a dynamic range of portion sizes.',
    header: 'ALL WHITE PORTION',
    form: 'Portion',
    manufacturer: 'GN Tobacco',
    productType: 'All White Portion',
    netWeight: '15 g',
    salt: '-',
    nicotineLevel: '33 mg/g',
    moisture: '46-47 %',
    image: `${process.env.PUBLIC_URL}/img/products/siberia/siberia-slim-portion-min.png`
  },
  {
    name: 'SIBERIA ORIGINAL',
    description: 'New Siberia All-White Portion - The famous flavor of Siberia is now available, as a Tobacco-free All-White portion in a dynamic range of portion sizes.',
    header: 'ALL WHITE PORTION',
    form: 'Portion',
    manufacturer: 'GN Tobacco',
    productType: 'All White Portion',
    netWeight: '15 g',
    salt: '-',
    nicotineLevel: '33 mg/g',
    moisture: '46-47 %',
    image: `${process.env.PUBLIC_URL}/img/products/siberia/siberia-original-portion-min.png`
  },
  {
    name: 'SIBERIA MAXI',
    description: 'New Siberia All-White Portion - The famous flavor of Siberia is now available, as a Tobacco-free All-White portion in a dynamic range of portion sizes.',
    form: 'Portion',
    manufacturer: 'GN Tobacco',
    productType: 'All White Portion',
    netWeight: '15 g',
    salt: '-',
    nicotineLevel: '33 mg/g',
    moisture: '46-47 %',
    image: `${process.env.PUBLIC_URL}/img/products/siberia/siberia-maxi-portion-min.png`
  }
]

export const siberiaProducts = [
  {
    name: 'SIBERIA TIGHT SERIES -80 DEGREES RED',
    header: 'WHITE DRY PORTION',
    description: 'Well rounded and flavorful chewing tobacco blend with classic and spicy Swedish tobacco flavor - peppery and with hints of citrus/bergamot.',
    form: 'Portion',
    manufacturer: 'GN Tobacco',
    productType: 'White Dry Portion',
    netWeight: '13 g',
    salt: '2.5 %',
    nicotineLevel: '43 mg/g',
    moisture: '-',
    image: `${process.env.PUBLIC_URL}/img/products/siberia/siberiaSlimSerie-min.png`
  },
  {
    name: 'SIBERIA TIGHT SERIES -80 DEGREES BROWN',
    description: 'Powerful chewing tobacco blend providing a strong and very special mint/spearmint experience. Strongest nicotine experience on the market in moistened chewing portion bags. Fast and powerful flavor release.',
    header: 'ORIGINAL PORTION',
    form: 'Portion',
    manufacturer: 'GN Tobacco',
    productType: 'Original Portion',
    netWeight: '20 g',
    salt: '2.5 %',
    nicotineLevel: '43 mg/g',
    moisture: '50%',
    image: `${process.env.PUBLIC_URL}/img/products/siberia/siberiaBrownTight-min.png`
  },
  {
    name: 'SIBERIA -80 DEGREES BROWN',
    description: 'Powerful chewing tobacco blend providing a strong and very special mint spearmint experience. Strongest nicotine experience on the market in moistened chewing portion bags rastand do vertunavor release',
    form: 'Portion',
    manufacturer: 'GN Tobacco',
    productType: 'Original Portion',
    netWeight: '20 g',
    salt: '2.5 %',
    nicotineLevel: '43 mg/g',
    moisture: '50 %',
    image: `${process.env.PUBLIC_URL}/img/products/siberia/siberiaBrown-min.png`
  },
  {
    name: 'SIBERIA TIGHT SERIES -80 DEGREES BLUE',
    description: 'Powerful chewing tobacco blend providing a strong and very special mint spearmint experience. Extremely strong nicotine experience in unmoistened chewing portion bags. Runs less and keeps its flavour longer.',
    header: 'ORIGINAL PORTION',
    form: 'Portion',
    manufacturer: 'GN Tobacco',
    productType: 'Original Portion',
    netWeight: '20 g',
    salt: '2.5 %',
    nicotineLevel: '24 mg/g',
    moisture: '-',
    image: `${process.env.PUBLIC_URL}/img/products/siberia/siberiaBlueSlim-min.png`
  },
  {
    name: 'SIBERIA -80 DEGREES BLACK',
    description: 'Powerful chewing tobacco blend providing a strong and very special mint/spearmint experience. Strongest nicotine experience on the market in moistened chewing portion bags. Fast and powerful flavour release.',
    header: 'ORIGINAL PORTION',
    form: 'Portion',
    manufacturer: 'GN Tobacco',
    productType: 'Original Portion',
    netWeight: '20 g',
    salt: '2.5 %',
    nicotineLevel: '43 mg/g',
    moisture: '50 %',
    image: `${process.env.PUBLIC_URL}/img/products/siberia/siberia-black-min.png`
  },
  {
    name: 'SIBERIA -80 DEGREES WHITE DRY',
    description: 'Powerful chewing tobacco blend with classic and spicy Swedish tobacco flavor-peppery and with flowery elements. strongest nicotine experience on the market in unmoistened chewing portion bags and with lower tobacco moisture, Runs even less than regular "White" bags - and keeps the lavor even longer.',
    header: 'ORIGINAL PORTION',
    form: 'Portion',
    manufacturer: 'GN Tobacco',
    productType: 'White Dry Portion',
    netWeight: '16 g',
    salt: '2.5 %',
    nicotineLevel: '43 mg/g',
    moisture: '-',
    image: `${process.env.PUBLIC_URL}/img/products/siberia/siberiaWhiteDry-min.png`
  },
  {
    name: 'SIBERIA -80 DEGREES WHITE',
    description: 'Powerful chewing tobacco blend providing a strong and very special mint/spearmint experience. Extremely strong nicotine experience in unmoistened chewing portion bags. Runs less and keeps its flavor longer.',
    header: 'ORIGINAL PORTION',
    form: 'Portion',
    manufacturer: 'GN Tobacco',
    productType: 'White Portion',
    netWeight: '20 g',
    salt: '2.5 %',
    nicotineLevel: '24 mg/g',
    moisture: '-',
    image: `${process.env.PUBLIC_URL}/img/products/siberia/siberiaDegreesWhite.png`
  },
]

export const hitProducts = [
  {
    name: "HIT BLACKBERRY TIGHT",
    header: 'ALL WHITE PORTION',
    description: 'HiT Blackberry is a white, tobacco-free product of extremely strong nicotine content with a fresh blackberry flavor. Imagine how the first blackberries of the harvest taste. Captured the essence of that. If you are in love with fresh blackberry, experience it in an all-white nicotine pouch form.',
    form: 'Portion',
    manufacturer: 'GN Tobacco',
    productType: 'ALL WHITE PORTION',
    netWeight: '15 g',
    salt: '-',
    nicotineLevel: '20 mg/g ',
    moisture: '-',
    image: `${process.env.PUBLIC_URL}/img/products/hit/hitBlackberry.png`
  },
  {
    name: "HIT BLUEBERRY TIGHT",
    header: 'ALL WHITE PORTION',
    description: 'HiT Blueberry is a white, tobacco-free product of extremely strong nicotine content with a fresh blueberry flavor. A delicate and refreshing flavor of blueberry is delivered as a smooth symphony. Prepare yourself for a delectable, smooth blueberry taste and extremely strong nicotine kick!',
    form: 'Portion',
    manufacturer: 'GN Tobacco',
    productType: 'ALL WHITE PORTION',
    netWeight: '15 g',
    salt: '-',
    nicotineLevel: '20 mg/g ',
    moisture: '-',
    image: `${process.env.PUBLIC_URL}/img/products/hit/hitBlueberry.png`
  },
  {
    name: "HIT DR. CHERRY TIGHT",
    header: 'ALL WHITE PORTION',
    description: 'HiT Dr. Cherry is a white, tobacco-free product of extremely strong nicotine content with the classic taste of the cherry taste soda. It should be obvious! The beloved cherry soda flavor without the excess sugar! If you like the drink, give this pouch a try!',
    form: 'Portion',
    manufacturer: 'GN Tobacco',
    productType: 'ALL WHITE PORTION',
    netWeight: '15 g',
    salt: '-',
    nicotineLevel: '20 mg/g ',
    moisture: '-',
    image: `${process.env.PUBLIC_URL}/img/products/hit/hitDepepper.png`
  },
  {
    name: "HIT APELSIN TIGHT",
    header: 'ALL WHITE PORTION',
    description: "HiT Apelsin is a white, tobacco-free product of extremely strong nicotine content with a refreshing and natural orange flavoring. Ripe orange pulp with a twist of orange zest, this flavor captures the flavor of a REAL fruit. Enjoy a citric twist, delightfully juicy flavor that'll leave your taste buds asking for more!",
    form: 'Portion',
    manufacturer: 'GN Tobacco',
    productType: 'ALL WHITE PORTION',
    netWeight: '15 g',
    salt: '-',
    nicotineLevel: '20 mg/g ',
    moisture: '-',
    image: `${process.env.PUBLIC_URL}/img/products/hit/hitApelsin.png`
  },
  {
    name: "HIT LEMONGRASS  TIGHT",
    header: 'ALL WHITE PORTION',
    description: 'Lemongrass is a white tobacco-free product of extremely strong nicotine content with a refreshing lemon-herbal flavor that you will not forget. A unique flavor is known for its intensity and energy. The vibrancy of flavor Lemongrass has is represented perfectly. Extremely strong nicotine content with a refreshing lemon-herbal taste that you will not forget.',
    form: 'Portion',
    manufacturer: 'GN Tobacco',
    productType: 'ALL WHITE PORTION',
    netWeight: '15 g',
    salt: '-',
    nicotineLevel: '20 mg/g ',
    moisture: '-',
    image: `${process.env.PUBLIC_URL}/img/products/hit/hitLemongrass.png`
  },

  {
    name: "ISLAY WHISKY",
    header: 'LOOSE',
    description: "Well rounded and high quality chewing tobacco blend with flavors of real Scottish whisky, made in a handcrafted process.",
    form: 'Loose',
    manufacturer: 'GN Tobacco',
    productType: 'Loose',
    netWeight: '50 g',
    salt: '2,5 %',
    nicotineLevel: '12 mg/g ',
    moisture: '50 %',
    image: `${process.env.PUBLIC_URL}/img/products/hit/islayLoose.png`
  },
]

export const allProductsFix = [
  ...bulldogProducts,
  ...whiteFoxProducts,
  ...siberiaAllWhiteProducts,
  ...siberiaProducts,
  ...hitProducts
]
export const allSiberiaProducts = [
  ...siberiaAllWhiteProducts,
  ...siberiaProducts
]

export const allProducts = [
  {
    name: 'ISLAY WHISKY LOOSE',
    header: 'LOOSE',
    description: 'Well rounded and high quality chewing tobacco blend with flavors of real Scottish whisky, made in a handcrafted process.',
    form: 'Loose',
    manufacturer: 'GN Tobacco',
    productType: 'Loose',
    netWeight: '50 g',
    salt: '2.5 %',
    nicotineLevel: '12 mg/g ',
    moisture: '50 %'
  },
]
