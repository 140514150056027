import React from 'react'
import { useParams } from 'react-router-dom'
import styles from './index.module.css'
import Header from '../../components/header'
import { getSchedule } from '../../services/api'
import ConfirmScheduleForm from '../../components/forms/confirmSchedule';
import LoadingSpinner from '../../components/LoadingSpinner';

function Schedule (params) {
  const { id } = useParams()
  const [schedule, setSchedule] = React.useState({})
  const [isLoading, setIsLoading] = React.useState(false)

  React.useEffect(() => {
    (async () => {
      setIsLoading(true)
      // TODO: find out why this is being executed twice
      const response = await getSchedule({ id })
      setSchedule(response)
      setIsLoading(false)
      // setIsScheduleFetched(true)
    })()
  }, [id])

  return (
    <div>
      <Header />
      <main className={styles['main']}>
        {
          isLoading
            ? <LoadingSpinner />
            : schedule.confirmed
              ? <>
                <h1>Your appointment has been scheduled!</h1>
                <span>Thank you for your interest in our services! It seems you have already used this link to schedule a meeting with us. We will get in touch soon.</span>
              </>
              : <ConfirmScheduleForm schedule={schedule} setIsLoading={setIsLoading} setSchedule={setSchedule} />
        }
        {/* {
          isScheduleFetched && !schedule.confirmed
            ?
            <div>
              <h1>Thank you for your interest in our services!</h1>
              <span>Please fill up the form with your details so we can get it touch.</span>

              <RenderFormOrLoading schedule={schedule} isScheduleFetched={isScheduleFetched} />
            </div>
            :
            <div>
              <h1>Your appointment has been scheduled!</h1>
              <span>Thank you for your interest in our services! It seems you have already used this link to schedule a meeting with us. We will get in touch soon.</span>
            </div>
        } */}

      </main>
    </div>
  )
}

export default Schedule;
