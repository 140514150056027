import React from 'react';
import Header from '../../../components/header';
import About from '../../../components/aboutUs';
import Brands from '../../../components/brands';
import Footer from '../../../components/footer';
import { allSiberiaProducts } from '../../../data/products'
import ProductsList from '../../../components/productsList';

function Siberia() {
    return (
                <div>
                    <Header />
                    <About
                        title={'SIBERIA'}
                        p1={"New Siberia All-White Portion - The famous flavour of Siberia is now available, as a Tobacco-free All-White portion in a dynamic range of portion sizes."}
                        p2={"Siberia nicotine pouches are one of the world's strongest."}
                        p3={"Packing 33 mg/g of Nicotine, icy cooling sensations, and Siberia's intense mint flavour profile, the only difference is that there is no Tobacco!"}
                    />
                    <ProductsList
                        productList={allSiberiaProducts}
                    />
                    <Brands />
                    <Footer />
                </div>
    );
}

export default Siberia;
