import Home from './pages/home'
import Products from './pages/products'
import Siberia from './pages/brands/siberia';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Schedule from './pages/schedule';
import WhiteFox from './pages/brands/whiteFox';
import Hit from './pages/brands/hit';
import Bulldog from './pages/brands/bulldog';


function App () {
  return (
    <Router>
      <div>
        <Routes>
          <Route
            path="/"
            element={<Home />}
          />
          <Route
            path="/products"
            element={<Products />}
          />
          <Route
            path="/siberia"
            element={<Siberia />}
          />
          <Route
            path="/whitefox"
            element={<WhiteFox />}
          />
          <Route
            path="/hit"
            element={<Hit />}
          />
          <Route
            path="/bulldog"
            element={<Bulldog />}
          />
          <Route
            path="/schedule/:id"
            element={<Schedule />}
          />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
