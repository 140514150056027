import React from 'react'
import { useParams } from 'react-router-dom'
import Button from '@mui/material/Button';
import styles from './index.module.css'
import { confirmSchedule } from '../../../services/api'
import { getDateFromNextDays } from '../../../helpers/date'

function ConfirmScheduleForm({ schedule, setIsLoading, setSchedule }) {
  const { id } = useParams()
  const [name, setName] = React.useState('');
  const [shopName, setShopName] = React.useState('');
  const [phoneNumber, setPhoneNumber] = React.useState('');
  const [postCode, setPostCode] = React.useState('');
  const [date, setDate] = React.useState('');
  const [period, setPeriod] = React.useState('');

  const next3DaysDate = getDateFromNextDays(3)

  const handleSubmit = React.useCallback(async () => {
    setIsLoading(true)

    const { schedule: updatedSchedule } = await confirmSchedule({
      id,
      name,
      shopName,
      phoneNumber,
      postCode,
      date,
      period,
      email: schedule.email
    })

    setIsLoading(true)
    setSchedule(updatedSchedule)
  }, [id, name, shopName, phoneNumber, postCode, date, period, setSchedule, setIsLoading, schedule])

  return (
    <div id="form" className={styles['form-container']} >
      <h1 className={styles['title']}>
        Schedule a visit with one of our representatives
      </h1>
      Email:
      <input className={styles['form-input']} type="email" name="email" placeholder={schedule.email || ''} disabled />
      Name:
      <input onChange={(e) => setName(e.target.value)} type="text" name="name" required />
      Shop Name:
      <input onChange={(e) => setShopName(e.target.value)} type="text" name="shopName" required />
      Phone Number:
      <input onChange={(e) => setPhoneNumber(e.target.value.toString())} type="number" name="phoneNumber" />
      Post Code:
      <input onChange={(e) => setPostCode(e.target.value)} type="text" name="postCode" required />


      <div className={styles['date-container']}>
        <h2>Date:</h2>
        <div className={styles['date-input-container']}>
          <input onChange={(e) => setDate(e.target.value)} type="date" name="date" min={next3DaysDate} required />
          <div className={styles['input-radio']}>
            <div className={styles['input-radio-align']}>
              <label>
                Morning
              </label>
              <input
                onChange={(e) => setPeriod(e.target.value)}
                type="radio"
                id="morning"
                name="time"
                value="Morning"
              />
            </div>
            <div className={styles['input-radio-align']}>
              <label>
                Afternoon
              </label>
              <input
                onChange={(e) => setPeriod(e.target.value)}
                type="radio"
                id="afternoon"
                name="time"
                value="Afternoon"
              />
            </div>
          </div>

        </div>

      </div>
      <Button
        className={styles['submit-btn']}
        variant="contained"
        onClick={handleSubmit}
      >
        Submit
      </Button>
    </div>
  )
}

export default ConfirmScheduleForm
