import React from 'react';
import styles from './index.module.css'

const Banner = () => {
    return (
      <div className={styles['banner']}></div>
    )
};

export default Banner;

