import React from 'react';
import Header from '../../../components/header';
import About from '../../../components/aboutUs';
import Brands from '../../../components/brands';
import Footer from '../../../components/footer';
import { hitProducts } from '../../../data/products'
import ProductsList from '../../../components/productsList';

function Hit() {
    return (
                <div>
                    <Header />
                    <About
                        title={'HIT'}
                        p1={"HIT is an all-white, tobacco-free product with extremely high nicotine content, inspired by the fierce black panther, and produced by GN Tobacco Sweden."}
                        p2={"With five fruity, crisp flavors, HIT hits the mark for everyone who's grown tired of the abundance of cloyingly sweet or uninspired white products. Most HIT flavors come in both slim and mini pouches, ensuring you can savor your white product in a discreet and classy way - without compromising on taste or strength."}
                        p3={"HIT comes in five different flavors all packing 20mg/g of Nicotine: Blackberry, Blueberry, Apelsin, Dr. Cherry, and Lemongrass. The flavor profiles were developed drawing on the inspiration for what stimulated and excited us."}
                    />
                    <ProductsList
                        productList={hitProducts}
                    />
                    <Brands />
                    <Footer />
                </div>
    );
}

export default Hit;
