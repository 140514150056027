import React from 'react';
import Header from '../../../components/header';
import About from '../../../components/aboutUs';
import Brands from '../../../components/brands';
import Footer from '../../../components/footer';
import { whiteFoxProducts } from '../../../data/products'
import ProductsList from '../../../components/productsList';

function WhiteFox() {
    return (
                <div>
                    <Header />
                    <About
                        title={'WHITE FOX'}
                        p1={"White Fox is a Tobacco-free product that does not contain any of the harmful components that Tobacco products may have."}
                        p2={"Sufficiently loaded with high quality Nicotine that is extracted using the most modern and purest of techniques, White Fox has very low levels of TSNA and heavy metals. We only use essential natural oils and no added sugars to ensure White Fox is one of the purest ways to attain Nicotine satisfaction."}
                        p3={"We create White Fox using a special fleece material for the portion paper. This unique material provides a symbiotic Nicotine/Flavour release offering a pleasant and consistent experience when compared to traditional papers. Available in 70 countries, White Fox is a leader in continuous development and innovation."}
                    />
                    <ProductsList
                        productList={whiteFoxProducts}
                    />
                    <Brands />
                    <Footer />
                </div>
    );
}

export default WhiteFox;
