import React from 'react';
import styles from './index.module.css'

const About = ({title, p1, p2, p3}) => {
    return (
        <div className={styles['about-container']}>
            <div className={styles['title-container']}>
                <h1>{title}</h1>
            </div>
            <div className={styles['text-container']}>
                <p>
                   {p1} 
                </p>
                <p>
                    {p2}
                </p>
                <p>
                    {p3}
                </p>
                
            </div>
        </div>
    )
};

export default About;

