import React from 'react';
import styles from './index.module.css'
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';

import LoadingSpinner from '../LoadingSpinner';

import { createSchedule } from '../../services/api'

function EmailForm({ handleSubmit, setEmail }) {
    return (
        <div className={styles['form-container']}>
            <span className={styles['form-description']}>Enter your email below and we'll send you a link</span>

            <input onChange={(e) => setEmail(e.target.value)} className={styles['form-input']} placeholder="email@gmail.com"
                type="email" name="email" required />
            <Button className={styles['submit-btn']} variant="contained" onClick={handleSubmit}>
                Submit
            </Button>
        </div>
    )
}

function FeedbackMessage({ handleClose }) {
    return (
        <div className={styles['form-container']}>
            <span className={styles['form-description']}>Thank you for your interest in our services!</span>
            <span className={styles['form-description']}>Please check your email to complete the next step.</span>
            <Button className={styles['submit-btn']} variant="contained" onClick={handleClose}>
                Close
            </Button>
        </div>
    )
}

const Schedule = () => {
    const [open, setOpen] = React.useState(false);
    const [email, setEmail] = React.useState('');
    const [loading, setLoading] = React.useState(false);
    const [hasFormBeenSubmitted, setHasFormBeenSubmitted] = React.useState(false);

    const handleOpen = () => setOpen(true);

    const handleClose = () => {
        setOpen(false);
        setHasFormBeenSubmitted(false);
    };

    const handleSubmit = React.useCallback(async () => {
        setLoading(true);
        setHasFormBeenSubmitted(true);

        await createSchedule({ email });
        setLoading(false);
    }, [email]);

    return (
        <div id='schedule' className={styles['schedule-container']}>
            <h1 className={styles['schedule-title']}>
                Want to schedule a visit with one of
                our representatives?
            </h1>
            <Button
                className={styles['schedule-btn']}
                variant="contained"
                onClick={handleOpen}
            >
                Schedule a visit
            </Button>

            <Modal
                open={open}
                outline='none'
                onClose={handleClose}
            >
                <div className={styles['modal-container']}>
                    <button 
                            className={styles['close-modal']}
                            onClick={handleClose}
                    />
                    <img className={styles['logo']} src={`${process.env.PUBLIC_URL}/img/logo-white.png`} alt="DDG's website logo" />
                    <div id="form" className={styles['form-container']}>
                        {
                            !hasFormBeenSubmitted
                                ? <EmailForm setEmail={setEmail} handleSubmit={handleSubmit} />
                                : loading
                                    ? <LoadingSpinner />
                                    : <FeedbackMessage handleClose={handleClose} />
                        }
                    </div>
                </div>
            </Modal>
        </div>
    )
}

export default Schedule
