import React from 'react';
import styles from './index.module.css'
import { Link } from 'react-scroll';

function handleClick(event, sectionId) {
  event.preventDefault();
  const section = document.getElementById(sectionId);
  if (section) {
    section.scrollIntoView({ behavior: 'smooth' });
  }
}

const Header = ({link1, link2}) => {
    return (
      <div className={styles['header']}>
        <a href='/'>
          <img className={styles['logo']} src={`${process.env.PUBLIC_URL}/img/logo-white.png`} alt="DDG's website logo" ></img>
        </a>
      
        <div className={styles['navigation']}>
          <a className={styles['navLink']} href='/products'>
            <h3>
              {link2}
            </h3>
          </a>
          <Link className={styles['navLink']} to="download"  smooth offset={-100} onClick={(e) => handleClick(e, 'product')}>
            <h3>
              {link1}
            </h3>
          </Link>
        </div>
      </div>
    )
};

export default Header;