import React from 'react';
import Header from '../../../components/header';
import About from '../../../components/aboutUs';
import Brands from '../../../components/brands';
import Footer from '../../../components/footer';
import { bulldogProducts } from '../../../data/products'
import ProductsList from '../../../components/productsList';

function Bulldog() {
    return (
                <div>
                    <Header />
                    <About
                        title={'BULLDOG'}
                        p1={'Bull Dog is a near "All White" product with just a minimum of tobacco.'}
                        p2={"BullDog offers a powerful and well rounded flavour experience. Clean and cooling flavours derived from real mint oils combine with classic and spicy Swedish tobacco - peppery and with hints of citrus/bergamot."}
                        p3={"The result is a strong nicotine experience delivered through unmoistened chewing portion bags. Less liquid retains flavour longer. Full sized portions for that comforting and familiar experience."}
                    />
                    <ProductsList
                        productList={bulldogProducts}
                    />
                    <Brands />
                    <Footer />
                </div>
    );
}

export default Bulldog;
