import axios from 'axios'

const client = axios.create({
  baseURL: process.env.REACT_APP_API_GATEWAY_ADDRESS,
  headers: {
    'Content-Type': 'application/json',
  },
})

export const createSchedule = async ({ email }) => {
  const { data } = await client.post('schedule/create', {
    email
  })
  return data

}

export const getSchedule = async ({ id }) => {
  const { data } = await client.get(`schedule?id=${id}`)
  return data
}

export const confirmSchedule = async ({ id, name, shopName, email, phoneNumber, postCode, date, period }) => {
  const { data } = await client.post(`schedule/confirm/${id}`, {
    schedule: {
      name,
      shopName,
      email,
      phoneNumber,
      postCode,
      date,
      period
    }
  })
  return data
}
