import React from 'react';
import styles from './index.module.css'

function Footer() {

	return (
        <div className={styles['footer-container']}>
            <div className={styles['contact-container']}>
                <p>Contact: support@ddg.im</p>
            </div>
            <p className={styles['copyright']}>
                © DDG LTD. 2022 All Rights Reserved
            </p>
        </div>
	);
}
export default Footer;


                    
                    

                    